import React from "react";
import Board from "./Board";
import PgnUploader from "./PgnUploader"; // Import the PGN Uploader
// import ActiveUsers from "./ActiveUsers";

const Chess = () => {
  return (
    <div className="chess-app">
      <h2>Chess Game</h2>
      <Board />
    </div>
  );
};

export default Chess;
