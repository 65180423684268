// moveAppwrite.js
import { ID, Databases } from "appwrite"; // Import Appwrite SDK
import client from "../appwriteConfig";

// Function to save the initial game details
export const saveGameDetailsToAppwrite = async (gameDetails) => {
  const databases = new Databases(client);
  try {
    const response = await databases.createDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES, // Replace with your actual collection ID
      ID.unique(), // Generate a unique document ID
      gameDetails // Game details passed as a parameter
    );
    console.log("Game details saved:", response);
    return response.$id; // Return the generated game ID
  } catch (error) {
    console.error("Error saving game details:", error);
  }
};

// Function to save each move in proper chess notation and append to existing moves
export const saveMoveToAppwrite = async (
  gameId,
  newMove, // The move string to be appended
  updatedBoardState,
  currentTurn
) => {
  const databases = new Databases(client);

  try {
    // Get the existing moves from the document
    const gameDocument = await databases.getDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES,
      gameId
    );

    let existingMoves = gameDocument.moves || ""; // Get existing moves (as string)

    // Append the new move to the string (with a space or comma as a separator)
    const updatedMoves = `${existingMoves} ${newMove}`.trim(); // Trim to avoid extra spaces

    // Update the document with the new moves (as a string)
    const response = await databases.updateDocument(
      process.env.REACT_APP_DATABASE_ID,
      process.env.REACT_APP_COLLECTION_CHESSGAMES,
      gameId,
      {
        currentTurn,
        boardState: updatedBoardState,
        moves: updatedMoves, // Save moves as a string
      }
    );

    console.log("Move saved:", response);
  } catch (error) {
    console.error("Error saving move:", error);
  }
};
