import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import { useUser } from "./UserContext"; // Ensure you import the custom hook
import { QRCodeCanvas } from "qrcode.react"; // For desktop QR code

const Checkout = () => {
  const { user } = useUser(); // Access the user data from context
  const location = useLocation();
  const { cart } = location.state || {};
  const [userName, setUserName] = useState(null); // State to hold the user name
  const [isMobile, setIsMobile] = useState(false); // State to detect if mobile

  // Detect if the user is on a mobile device
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android|iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobile(true); // Mobile browser detected
    } else {
      setIsMobile(false); // Desktop browser detected
    }
  }, []);

  // Set userName from user.name if user data is available
  useEffect(() => {
    if (user && user.name) {
      setUserName(user.name);
    } else {
      setUserName(null);
    }
  }, [user]);

  // Calculate total price from the cart
  const calculateTotalPrice = (cart) => {
    return cart.reduce(
      (total, item) =>
        total + Number(item.totalCost || item.cost * (item.quantity || 1)),
      0
    );
  };

  const totalPrice = calculateTotalPrice(cart);

  // Generate UPI link
  const upiID = "leonardlaureate@okicici"; // Your UPI ID
  const upiLink = userName
    ? `upi://pay?pa=${upiID}&pn=${encodeURIComponent(
        userName
      )}&am=${totalPrice}&cu=INR&tn=Payment`
    : "";

  return (
    <div className="checkout-container">
      <div className="form-container-checkout">
        <h2 className="title-checkout">Checkout</h2>
        <div>
          {cart && cart.length > 0 ? (
            <div>
              {cart.map((item, index) => (
                <div key={index}>
                  <span className="product-name-checkout">
                    {item.productName} x {item.quantity}
                  </span>
                  <span className="product-cost-checkout">
                    ₹{item.cost} x {item.quantity} = ₹
                    {item.cost * item.quantity}
                  </span>
                </div>
              ))}
              <p className="total-price-checkout">Total Price: ₹{totalPrice}</p>
            </div>
          ) : (
            <p>No items in the cart.</p>
          )}
        </div>

        <Formik
          initialValues={{ mobileNumber: "" }}
          onSubmit={() => {
            /* No need for payment API calls */
          }}
        >
          {({ values }) => (
            <Form>
              <label className="form-label-checkout">Mobile Number</label>
              <Field
                className="input-field-checkout"
                type="text"
                name="mobileNumber"
                required
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
              />
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="error-message-checkout"
              />

              {/* Show both UPI Button and QR Code */}
              {userName ? (
                <div>
                  <a href={upiLink} className="upi-button-checkout">
                    Pay Now via UPI
                  </a>

                  {/* Show the QR code for both mobile and desktop */}
                  <div>
                    <p>Alternatively, scan this QR code with any UPI app:</p>
                    <QRCodeCanvas value={upiLink} size={200} />
                  </div>
                </div>
              ) : (
                <ClipLoader color="#000000" loading={true} size={20} />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Checkout;
