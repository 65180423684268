import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Hook to get the roomId from navigation state
import { useUser } from "../UserContext";
import client from "../appwriteConfig";
import { ID, Databases, Query } from "appwrite";
import { FaTrash } from "react-icons/fa";

const databases = new Databases(client);

const PrivateRoom = () => {
  const { user } = useUser();
  const location = useLocation();
  const roomId = location.state?.roomId; // Get roomId from location state
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const databaseId = process.env.REACT_APP_DATABASE_ID;
  const collectionId = process.env.REACT_APP_COLLECTION_MESSAGES;

  // Fetch messages for the room when component mounts
  useEffect(() => {
    if (roomId) {
      fetchMessages(roomId);
      const unsubscribe = client.subscribe(
        `databases.${databaseId}.collections.${collectionId}.documents`,
        (response) => {
          if (
            response.events.includes(
              "databases.*.collections.*.documents.*.create"
            )
          ) {
            setMessages((prevMessages) => [...prevMessages, response.payload]);
          } else if (
            response.events.includes(
              "databases.*.collections.*.documents.*.delete"
            )
          ) {
            setMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.$id !== response.payload.$id)
            );
          }
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [roomId]);

  const fetchMessages = async (roomId) => {
    try {
      const result = await databases.listDocuments(databaseId, collectionId, [
        Query.equal("roomId", roomId),
      ]);
      setMessages(result.documents);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() !== "") {
      try {
        await databases.createDocument(databaseId, collectionId, ID.unique(), {
          message: message,
          senderName: user?.name || "Anonymous",
          senderEmail: user?.email || "No email provided",
          roomId: roomId, // Link message to the room
        });
        setMessage(""); // Clear input after sending message
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await databases.deleteDocument(databaseId, collectionId, messageId);
      setMessages(messages.filter((msg) => msg.$id !== messageId));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  return (
    <div>
      <h1>Private Room Chat</h1>

      {user ? (
        <div className="user-info">
          <p>
            <strong>Name:</strong> {user.name || "Anonymous"}
          </p>
          <p>
            <strong>Email:</strong> {user.email || "No email provided"}
          </p>
        </div>
      ) : (
        <p>No user information available</p>
      )}

      <div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button onClick={handleSendMessage}>Send Message</button>
      </div>

      <div>
        <h2>Messages:</h2>
        <ul>
          {messages.map((msg) => (
            <li key={msg.$id}>
              <strong>
                {new Date(msg.$createdAt).toLocaleString()} ({msg.senderEmail}):
              </strong>{" "}
              {msg.message}
              <button onClick={() => handleDeleteMessage(msg.$id)}>
                <FaTrash />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrivateRoom;
