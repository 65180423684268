// UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { Account, Databases, ID, Query, Storage } from "appwrite";
import client from "./appwriteConfig";

const UserContext = createContext();
export const useUser = () => useContext(UserContext);
const DATABASE_CARTID = process.env.REACT_APP_DATABASE_ID;
const COLLECTION_CARTID = process.env.REACT_APP_COLLECTION_CART_ID;

export const UserProvider = ({ children }) => {
  const account = new Account(client);
  const storage = new Storage(client);
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState([]);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cartLoading, setCartLoading] = useState({});

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const stopCurrentTrack = () => {
    setIsPlaying(false);
    setCurrentTrack(null);
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await account.getSession("current");
        const userData = await account.get();
        setUser(userData);
        fetchCartData();
      } catch (error) {
        console.log("No active session");
        setUser(null);
      }
    };

    checkSession();
  }, []);

  useEffect(() => {
    fetchCartData();
  }, [user]);

  const login = async (email, password, onLoginSuccess = () => {}) => {
    try {
      await account.createEmailPasswordSession(email, password);
      const userData = await account.get();
      setUser(userData);
      onLoginSuccess();
    } catch (error) {
      throw new Error(
        "Invalid credentials. Please check the email and password."
      );
    }
  };

  const logout = async () => {
    try {
      await account.deleteSession("current");
      setUser(null);
      setCart([]); // Clear cart on logout
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  const register = async (
    name,
    email,
    password,
    onRegistrationSuccess = () => {}
  ) => {
    try {
      await account.create(ID.unique(), email, password, name);
      await login(email, password, onRegistrationSuccess);
    } catch (error) {
      console.error("Registration failed:", error.message);
      throw new Error(error.message);
    }
  };

  const loginWithPhone = async (phone, otp, onLoginSuccess = () => {}) => {
    try {
      // Step 1: Check if a token already exists for the phone number
      let token = localStorage.getItem(`phoneToken_${phone}`);

      // If no token is found, create a new one
      if (!token) {
        token = await account.createPhoneToken(ID.unique(), phone);
        localStorage.setItem(`phoneToken_${phone}`, JSON.stringify(token));
      } else {
        token = JSON.parse(token);
      }

      if (otp) {
        // Step 2: Use the OTP to create a session
        await account.createSession(token.userId, otp);
        const userData = await account.get();
        console.log("User after phone login:", userData);
        setUser(userData); // Set the user data with updated name
        localStorage.removeItem(`phoneToken_${phone}`); // Clear the token after successful login
        onLoginSuccess();
      }
    } catch (error) {
      localStorage.removeItem(`phoneToken_${phone}`); // Clear the token if there's an error
      throw new Error("Failed to login with phone. Please check the OTP.");
    }
  };

  const registerWithPhone = async (
    phone,
    otp = "",
    name = "",
    onRegistrationSuccess = () => {}
  ) => {
    try {
      // Check if a token already exists for the phone number
      let token = localStorage.getItem(`phoneToken_${phone}`);

      if (!token) {
        // Create a new token if it doesn't exist
        token = await account.createPhoneToken(ID.unique(), phone);
        localStorage.setItem(`phoneToken_${phone}`, JSON.stringify(token));
      } else {
        token = JSON.parse(token);
      }

      if (otp) {
        // Use the OTP to create a session
        await account.createSession(token.userId, otp);
        const userData = await account.get();

        // Update user's name after OTP verification
        if (name) {
          await account.updateName(name);
          userData.name = name; // Update local user data with new name
        }

        setUser(userData); // Set the user data with updated name
        localStorage.removeItem(`phoneToken_${phone}`); // Clear token after registration
        onRegistrationSuccess();
      }
    } catch (error) {
      localStorage.removeItem(`phoneToken_${phone}`); // Clear token if there's an error
      throw new Error("Failed to register with phone. Please check the OTP.");
    }
  };
  const fetchCartData = async () => {
    const database = new Databases(client, "default");
    if (user) {
      try {
        const { documents } = await database.listDocuments(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_CART_ID,
          [Query.equal("userId", user.$id)]
        );
        setCart(documents);
      } catch (error) {
        console.error("Failed to fetch cart data:", error);
      }
    }
  };

  const addToCart = async (product, quantity = 1) => {
    if (!user) {
      alert("Can't add to cart without logging in.");
      return; // Stop the function if there is no logged-in user
    }

    setCartLoading((prev) => ({ ...prev, [product.$id]: true })); // Start loading

    let totalCost = product.cost * quantity; // Calculate total cost
    let cartData = {
      userId: user.$id,
      email: user.email ? user.email : null, // Save email as null if not available
      mobileNumber: user.phone ? user.phone : null, // Save phone as null if not available

      name: user.name,
      productId: product.$id,
      productName:
        product.beatName || product.tutorialTitle || product.itemName,
      cost: product.cost,
      productType: product.productType,
      quantity: quantity,
      totalCost: totalCost, // Add total cost
    };

    if (product.productType === "beat") {
      const beatInfo = JSON.stringify({
        artistName: product.artistName,
        imageUrl: product.imageUrl,
        audioUrl: product.audioUrl,
        lowAudioUrl: product.lowAudioUrl,
        // email: product.email,
      });

      cartData = {
        ...cartData,
        sellerEmail: product.email,
        BEATinfo: beatInfo, // Store the consolidated beat information
      };
    } else if (product.productType === "fashion") {
      const fashionInfo = JSON.stringify({
        fashionUrl: product.fashionUrl,
        cost: product.cost,
        quantity: quantity,
        // email: product.email,
      });

      cartData = {
        ...cartData,
        sellerEmail: product.email,
        FASHIONinfo: fashionInfo, // Store the consolidated fashion information
      };
    } else if (product.productType === "tutorial") {
      const tutorialInfo = JSON.stringify({
        tutorialTitle: product.tutorialTitle,
        videoUrl: product.videoUrl,
        authorName: product.authorName,

        thumbnailUrl: product.thumbnailUrl,
        cost: product.cost,
      });

      cartData = {
        ...cartData,
        sellerEmail: product.email,
        TUTORIALinfo: tutorialInfo, // Store the consolidated tutorial information
      };
    }

    try {
      const addedCart = new Databases(client, "default");
      await addedCart.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_CART_ID,
        ID.unique(),
        cartData
      );
      fetchCartData();
    } catch (error) {
      console.error("Failed to add to cart in database:", error);
    } finally {
      setCartLoading((prev) => ({ ...prev, [product.$id]: false })); // End loading
    }
  };

  const updateCartItem = async (productId, quantity) => {
    setCartLoading((prev) => ({ ...prev, [productId]: true })); // Start loading
    const totalCost =
      cart.find((item) => item.productId === productId).cost * quantity;
    try {
      const database = new Databases(client, "default");
      const response = await database.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_CART_ID,
        [Query.equal("productId", productId)]
      );
      if (response.documents.length > 0) {
        const documentId = response.documents[0].$id;
        await database.updateDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_CART_ID,
          documentId,
          {
            quantity: quantity,
            totalCost: totalCost,
          }
        );
        fetchCartData();
      }
    } catch (error) {
      console.error("Failed to update cart item in the database:", error);
    } finally {
      setCartLoading((prev) => ({ ...prev, [productId]: false })); // End loading
    }
  };

  const removeFromCart = async (productId) => {
    setCartLoading((prev) => ({ ...prev, [productId]: true })); // Start loading
    try {
      const database = new Databases(client, "default");
      const response = await database.listDocuments(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_CART_ID,
        [Query.equal("productId", productId)]
      );
      if (response.documents.length > 0) {
        const documentId = response.documents[0].$id;
        await database.deleteDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_CART_ID,
          documentId
        );
        fetchCartData();
      }
    } catch (error) {
      console.error("Failed to remove item from the database:", error);
    } finally {
      setCartLoading((prev) => ({ ...prev, [productId]: false })); // End loading
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        storage,
        currentTrack,
        isPlaying,
        login,
        logout,
        cart,
        register,
        cartLoading,
        addToCart,
        removeFromCart,
        registerWithPhone,
        fetchCartData,
        updateCartItem,
        setUser,
        setCurrentTrack,
        loginWithPhone,
        setIsPlaying,
        togglePlay,
        stopCurrentTrack,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
